export default {
  API_ROOT: "api.hri.gtpdev.com",
  TITLE: "Hard Rock Jackpot Casino - Dev",
  FACEBOOK_PIXEL_BASE: undefined,
  XTREMEPUSH_ID: "IySz1inhhyBmmy3mKyp0FBtTQHCiDWxH",
  APPLICATION_TARGET: "hri",
  APPLICATION_NAME: "HRI Dev",
  GOOGLE_CLIENT_ID: "720915024349-8itqk14ncab1aq3eg43mjkjsqisr7gmh.apps.googleusercontent.com",
  GOOGLE_AD_URL: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
  ENVIRONMENT: "dev",
  PRELOAD_ASSETS: "true",
  MOBILE_WEB_ONE_LINK: "https://hardrocksocialcasino.onelink.me/gyCq/cfad1b45",
  HELP_LINK: "hardrocksocialcasinohelp.zendesk.com",
  SITE_KEY: "6Le22jcUAAAAAJoEKGduRlplqw7scLoVxpwTjo1n",
  ACCOUNT_EMAIL: "false",
  ACCOUNT_CHANGE_PASSWORD: "false",
  PLAY_AS_GUEST: "true",
  APPLE_STORE_URL: "https://apps.apple.com/us/app/hri-client-blue-bat-internal/id1270862830",
  ANDROID_STORE_URL: "https://play.google.com/store/apps/details?id=com.greentubepro.hri",
  DSDK_APP_TOKEN: "40042bc4-11d2-4461-bb64-ef383aaa40f8",
  DSDK_HASH_KEY: "32E12DBA234A54DAACEF5",
  DSDK_SV_URL: "https://analytics.bluebat.dive.games/hri",
  DSDK_API_URL: "https://api-sandbox.bluebat.dive.games",
  DSDK_DOMAIN: "hri.gtpdev.com",
  EXPERIAN_ENVIRONMENT_KEY: undefined,
  SSO_DEV: undefined,
  NODE_ENV: "production",
  CDN_ROOT: "hri.gtpdev.com",
  APP_ID: undefined,
  VERSION_MAJOR: "2",
  VERSION_MINOR: "14",
  VERSION_PATCH: "0",
  VERSION_BUILD: "1",
  PUBLIC_URL: undefined,
  ZENDESK_URL: "https://hardrocksocialcasinohelp.zendesk.com/hc/en-us/requests/new",
  LINKING_CARD_POPUP_ON_HIT_REWARD_CENTER: "false",
  SHOW_PROPERTY_SELECTOR: "true",
  DYNAMIC_HIGHLIMIT_POPUP: "true",
  DYNAMIC_PROPERTY: "true",
  KEYCHAIN_SERVICE_KEY: undefined,
  SHARED_PREFERENCES_KEY: undefined,
  DIVE_ERROR_HANDLING: "yes" ? "yes" : 'no',
  ENABLE_API_TRACKING: "true",
  GOOGLE_TAG_MANAGER_CONTAINER_ID: "GTM-TB7PKKW",
  APPSFLYER_PATH_PREFIX: undefined,
  LANGUAGE: "en",
  PLAY_AS_GUEST_TYPE: "BUTTON",
  ONE_TRUST_DATA_DOMAIN_SCRIPT: undefined,
  FACEBOOK_LOGIN: "true",
  COMING_SOON: "false",
  MOBILE_WEB_SUPPORT: undefined
};